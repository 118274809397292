/* globals $ */
document.addEventListener('triggerJQuery', function () {
  if (!($('.service_requests').length > 0)) { return }

  $('#service_request_reason').change(function () {
    const emailLink = document.querySelector('.mail_delivery_link');
    if (!emailLink) return; // Exit if emailLink is not found

    emailLink.style.display = 'none'; // Hide the link by default

    const selectedValue = this.value; // Get selected option value
    const url = new URL(emailLink.href, window.location.origin);

    switch (selectedValue) {
      case 'Copy of current contract':
        if (!emailLink.dataset.latestEnrollmentId) return; // Abort if no enrollment ID
        url.searchParams.set('template_type', 'enrollment_document');
        url.searchParams.set('enrollment_id', emailLink.dataset.latestEnrollmentId);
        break;

      case 'Copy of cancel notice':
        url.searchParams.set('template_type', 'cancel_notice');
        url.searchParams.delete('enrollment_id');
        break;

      default:
        return; // Exit early if no matching case
    }

    // Show and update the link
    emailLink.href = url.toString();
    emailLink.style.display = 'inline-block';
  })

  $('#service_request_request_type').change(function () {
    $('#service_request_reason').empty()
    $('#service_request_reason').append($('<option>', { value: '', text: 'Please select' }))
    if (!$(this).val()) { return }

    const reasonArray = eval(`reasons_${$(this).val()}`)

    for (let key in reasonArray) {
      const text = reasonArray[key]
      $('#service_request_reason').append($('<option>', { value: text, text }))
    }

    $('#service_request_string_field_1').val('')
    $('#service_request_date_field_1').val('')
    $('#service_request_decimal_field_1').val('')
    $('#date_field_1, #string_field_1, #decimal_field_1').hide()
    document.getElementById("string_field_1").className = 'col-md-4'

    if ($(this).val() === 'refund') {
      $('#string_field_1, #decimal_field_1').show()
      $('#string_field_1').find('label').html($('#string_field_1').data('labels')['refund'])
      $('#decimal_field_1').find('label').html($('#decimal_field_1').data('labels')['refund'])
    } else if ($(this).val() === 'payment') {
      $('#date_field_1, #string_field_1, #decimal_field_1').show()
      $('#date_field_1').find('label').html($('#date_field_1').data('labels')['payment'])
      $('#string_field_1').find('label').html($('#string_field_1').data('labels')['payment'])
      $('#decimal_field_1').find('label').html($('#decimal_field_1').data('labels')['payment'])
    } else if ($(this).val() === 'resubmit') {
      $('#string_field_1').show()
      $('#string_field_1').find('label').html($('#string_field_1').data('labels')['resubmit'])
    } else if ($(this).val() === 'incentive') {
      $('#decimal_field_1').show()
      $('#decimal_field_1').find('label').html($('#decimal_field_1').data('labels')['incentive'])
    } else if ($(this).val() === 'drop') {
      $('#string_field_1').show()
      $('#service_request_string_field_1').val($('#string_field_1').data('email'))
      $('#string_field_1').find('label').html($('#string_field_1').data('labels')['drop'])
    } else if (['reject', 'change_reject', 'drop_reject'].includes($(this).val())) {
      document.getElementById("string_field_1").className += ' col-md-8'
      $('#date_field_1, #string_field_1').show()
      $('#date_field_1').find('label').html($('#date_field_1').data('labels')['reject'])
      $('#string_field_1').find('label').html($('#string_field_1').data('labels')['reject'])
    }
  })

  $('#batch_form input[name="service_request_id[]"]').click(function () {
    if ($('#batch_form input[name="service_request_id[]"]:checked').length > 0) {
      $('#batch-update-button').show()
    } else {
      $('#batch-update-button').hide()
    }
  })

  $('#batch_select_all').click(function () {
    if ($('#batch_form input[name="service_request_id[]"]:checked').length > 0) {
      $('#batch_form input[name="service_request_id[]"]').prop('checked', false)
      $('#batch-update-button').hide()
    } else {
      $('#batch_form input[name="service_request_id[]"]').prop('checked', true)
      $('#batch-update-button').show()
    }
    return false
  })

  $('#batch_cancel, #batch_complete').click(function () {
    $('input[name="batch_action"]').val($(this).attr('id'))
    if (window.confirm('Batch update selected requests?')) {
      $('form#batch_form').submit()
    }
    return false
  })
})
